:root {

    --range-handle-color: var(--color-actionelements);
    --range-handle-color-hover: var(--color-actionelements-active);
    --range-handle-size: 28px;
    --range-handle-margin-top: calc(-0.5 * var(--range-handle-size));
    --range-handle-width: calc(0.5 * var(--range-handle-size));
  
    --range-track-color: var( --color-supportelements-active);
    --range-track-color-focus: var(--color-actionelement);
    --range-track-height: 6px;
    --range-track-width: 100%;
  
}


/* input slider general styling */

input.slider[type=range] {
    -webkit-appearance: none;
    position: relative;
    margin: 0;
    height: 0;
    padding: 0;
    top: calc(0.5*var(--range-handle-size));
    width: var(--range-track-width);
    margin-top: 0;
  }
  
  input.slider[type=range]:focus {
    outline: none !important;
    box-shadow: 0px 0px 8px 4px var(--color-actiontext) !important;
  }
  
  /* input range handle style*/
  
  input.slider[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    width: var(--range-handle-width);
    border-radius: 3px;
    height: var(--range-handle-size);
    background: var(--range-handle-color);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: var(--range-handle-margin-top);
  }
  
  input.slider[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    width: var(--range-handle-width);
    border-radius: 3px;
    height: var(--range-handle-size);
    background: var(--range-handle-color);
    cursor: pointer;
  }
  
  input.slider[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border: 1px solid #000000;
    width: var(--range-handle-width);
    border-radius: 3px;
    height: var(--range-handle-size);
    background: var(--range-handle-color);
    cursor: pointer;
  }
  
  
  /* input range track style*/
  input.slider[type=range]::-webkit-slider-runnable-track {
    width: var(--range-track-width);
    height: var(--range-track-height);
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
    background: var(--range-track-color);
    cursor: pointer;
  }
  input.slider[type=range]::-moz-range-track {
    width: var(--range-track-width);
    height: var(--range-track-height);
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
    background: var(--range-track-color);
    cursor: pointer;
  }
  
  input.slider[type=range]::-ms-track {
    width: var(--range-track-width);
    height: var(--range-track-height);
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  
  input.slider[type=range]::-ms-fill-lower {
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: var(--range-track-color);
  }
  
  input.slider[type=range]::-ms-fill-upper {
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: var(--range-track-color);
  }
  
  input.slider[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--range-track-color);
  }
  input.slider[type=range]:focus::-ms-fill-lower {
    background: var(--range-track-color);
  }
  input.slider[type=range]:focus::-ms-fill-upper {
    background: var(--range-track-color);
  }
  
  