/*General colors*/

:root {

    --color-background: #000000;
    
    /*
    //test colors using lediz blue
    --color-actionelements: #3556f8;
    --color-actionelements-active: #4a67fa;
    --color-actionelements-shadow: #2a44c5;
    --color-actionelements-dark: #16257c;
    --color-actiontext: #ffffff;
    */

    --color-actionelements: #0280a0;
    --color-actionelements-active: #0088aa;
    --color-actionelements-shadow: #016a85;
    --color-actionelements-dark: #014b5e;
    --color-actiontext: #ffffff;
    
    --color-supportelements-shadow: #1c1e20;
    --color-supportelements: #2c2f33;
    --color-supportelements-active: #494d53;
    --color-mediumgrey: #60676f;
    --color-lightgrey: #959ba3;
    --color-lightgrey-highlight: #b7bbc1; 
    --color-supporttext: #caccce; /*#dddfe1*/

    --color-text-onbackground: var(--color-lightgrey);
    --color-input-background: var(--color-lightgrey-highlight);

    --color-alert: #e99242;
    --color-ok: #6a8d0d;

    --color-readonly-background: var(--color-lightgrey-highlight);
    --color-readonly-text: var(--color-supportelements);
    --color-readonly-border: var(--color-lightgrey-highlight);

    --color-greyout: #000000e5;
    --color-modal-background: var(--color-supportelements-shadow);
    --color-modal-text: var(--color-lightgrey-highlight);
    
    --checkbox-color: var(--color-actionelements);
    --checkbox-background: var(--color-supporttext);

    /*Get filters for black to RGB from: https://codepen.io/sosuke/pen/Pjoqqp */

    --filter-white: invert(96%) sepia(4%) saturate(0%) hue-rotate(358deg) brightness(104%) contrast(106%);
    --filter-actiontext: var(--filter-white);
    /*--filter-supporttext: invert(99%) sepia(10%) saturate(52%) hue-rotate(58deg) brightness(115%) contrast(85%);*/ /*#dddfe1*/
    --filter-supporttext: invert(93%) sepia(9%) saturate(52%) hue-rotate(169deg) brightness(86%) contrast(94%);
    --filter-lightgrey-highlight: invert(90%) sepia(10%) saturate(137%) hue-rotate(177deg) brightness(83%) contrast(87%);
    --filter-lightgrey: invert(65%) sepia(0%) saturate(24%) hue-rotate(209deg) brightness(97%) contrast(94%);
    --filter-mediumgrey: invert(73%) sepia(10%) saturate(20%) hue-rotate(356deg) brightness(92%) contrast(89%);
    --filter-supportelement: invert(12%) sepia(12%) saturate(563%) hue-rotate(175deg) brightness(101%) contrast(84%);
    --filter-alert: invert(66%) sepia(93%) saturate(742%) hue-rotate(329deg) brightness(96%) contrast(90%);
    --filter-ok: invert(49%) sepia(58%) saturate(4183%) hue-rotate(51deg) brightness(94%) contrast(90%);
}
