:root {
  --debug-outline: 1px solid grey;
  background-color: var(--color-background);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:not(input):not(textarea) {
  -webkit-user-select: none; /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
}

/*Simple spin animation for processing symbol*/
/* Safari 4.0 - 8.0 */
@-webkit-keyframes spin {    
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% { 
    -webkit-transform: rotate(360deg);
  }
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s linear 0.1s infinite;
  -moz-animation: 2s linear 0.1s none infinite spin;
  -webkit-animation: spin 2s linear 0.1s infinite;
  -0-animation: spin 2s linear 0.1s infinite;
  -webkit-animation-play-state: running;
  -o-animation-play-state: running;
  -moz-animation-play-state: running;
  animation-play-state: running;
}

/* horizontal flip transform */
.flip-horizontal {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
}

.rotate-90clockwise {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-90counterclockwise {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
