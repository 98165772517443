.session-banner {
    position: relative;
    display: block;
    text-align: center;
    width: 100%;
    max-height: 144px;
    background: var(--color-supportelements);
    border-radius: 0px;
}

.session-banner-header{
    top: 0;
}

.session-banner-footer {
    bottom: 0;
}

.session-banner img{
    filter: var(--filter-lightgrey);
    width: 100%;
    max-height: 144px; 
}