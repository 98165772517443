.controls {
    --controls-listButtonSize: 35px;
    --controls-smallButtonSize: 35px;
    --controls-largeButtonSize: 70px;
    --controls-button-radius: 5px;
    --controls-button-margin: 4px;
    --controls-list-margin: 0.5em;
}

.controls-large-btn {
    min-width: var(--controls-largeButtonSize);
    height: var(--controls-largeButtonSize);
    padding: 5px;
    margin-right: var(--controls-button-margin);
    margin-left: 0;
    border-radius: var(--controls-button-radius);
}

.controls-large-btn label {
    margin: 5px;
    white-space: pre-wrap;
    text-align: left;
}

.controls-small-btn, .controls-small-btn-secondary {
    min-width: var(--controls-smallButtonSize);
    height: var(--controls-smallButtonSize);
    padding: 3px;
    border-radius: var(--controls-button-radius);
}

.controls-list-btn, .controls-list-btn-secondary {
    min-width: var(--controls-listButtonSize);
    width: var(--controls-listButtonSize);
    height: var(--controls-listButtonSize);
    padding: 3px;
    border-radius: var(--controls-button-radius);
}

.controls-list-btn-secondary img, .controls-small-btn-secondary img {
    filter: var(--filter-lightgrey) !important;
}

.controls-large-btn img, .controls-small-btn img, .controls-list-btn img, .controls-list-btn-secondary img, .controls-small-btn-secondary img {
    height: 100%;
    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.controls-intro-container {
    width: 100%;
    display: grid;
    grid-gap: 0px 10px;
    grid-template-columns: minmax(200px, 1fr) 3.8em var(--controls-listButtonSize);
}

.controls-intro-container div, .controls-intro-container label {
    text-align: left;
    margin-top: var(--controls-list-margin);
}

.controls-intro-container input {
    margin-top: var(--controls-list-margin);
    max-height: var(--controls-listButtonSize);
    border-radius: 2px;
}

.controls-griditem-underline {
    border-bottom: 1px solid var(--color-supportelements-active);
}

.controls-matching-debug-container {
    width: 100%;
    display: grid;
    grid-gap: 0px 10px;
    grid-template-columns: 9em auto;
}

.controls-matching-debug-container div, .controls-matching-debug-container label{
    text-align: left;
    margin-top: var(--controls-list-margin);
}
