.app-header, .app-body{
    height: 100vh;
    width: 100%;
    top: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
}