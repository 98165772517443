
/*Color filters*/
.filter-white{
    filter: var(--filter-white);
}

.filter-supporttext{
    filter: var(--filter-supporttext);
}

.filter-lightgrey-highlight {
    filter: var(--filter-lightgrey-highlight);
}

.filter-lightgrey{ /*darkgrey: #a9a9a9 */
    filter: var(--filter-lightgrey);
}

.filter-mediumgrey{ /*grey: #808080 */
    filter: var(--filter-mediumgrey);
}

.filter-supportelement{ /* #2c2f33 */
    filter: var(--filter-supportelement);
}
  
.filter-alert{ /* #e99242 */
    filter: var(--filter-alert);
}

.filter-ok{ /* #6a8d0d */
    filter: var(--filter-ok);
}

.app-header, .app-body {
    background-color: var(--color-background);
    color: var(--color-text-onbackground);
}

h1, h2, h3, h4, h5 {
    color: var(--color-text-onbackground);
}
  
.label-onbackground{
    color: var(--color-text-onbackground);
}
  
.input-onbackground{
    background: var(--color-input-background);
    border: 1px solid var(--color-supportelements);
}

input, select, textarea {
    color: var(--color-supportelements);
}

input[type=text], textarea {
    box-shadow: 0 0 0 2px transparent;
}

textarea {
    outline: none;
}

input[type=text]:focus, textarea:focus {
    outline: none;
    border: 1px solid transparent;
    box-shadow: 0 0 0 2px var(--color-actionelements);
}

.div-readonlyinput{
    background: var(--color-readonly-background);
    color: var(--color-readonly-text);
    border: 1px solid var(--color-readonly-border);
}

.btn-checkbox {
    background-color: var(--checkbox-background);
    color: transparent;
    border: 2px solid var(--checkbox-background);
    border-radius: 2px;
    box-shadow: 0px 0px 0 1px var(--color-supportelements-shadow);
    outline: none;
}

/* Use button as checkbox */

.btn-checkbox:active, .btn-checkbox.active{
    background-color: var(--color-actionelements);
}
  
.btn-checkbox:hover:not([disabled]), .btn-checkbox:focus:not([disabled]){
    box-shadow: 0px 0px 5px 3px var(--color-actiontext);
    outline: 1px solid var(--color-actionelements);
}

.btn-checkbox[disabled=disabled], .btn-checkbox:disabled{
    background-color: var(--color-lightgrey) !important;
    outline: 1px solid var(--color-lightgrey) !important;
}

/* Use button as togglebutton */

.btn-toggle {
    background-color: var(--color-actiontext);
    color: transparent;
    border: 3px solid var(--color-actiontext);
    box-shadow: 0px 0px 0 1px var(--color-supportelements-shadow);
    outline: 1px solid var(--color-supportelements-shadow);
}
  
.btn-toggle.active {
    background-color: var(--color-actionelements);
}

/* buttons */

.btn[disabled=disabled], .btn:disabled {
    background: var(--color-mediumgrey);
    color: var(--color-lightgrey);
}

.btn[disabled=disabled] img, .btn:disabled img{
    filter: var(--filter-lightgrey);
}

.btn-invisible{
    background: var(--color-background);
    color: var(--color-supporttext);
    border: 0px solid transparent;
    border-radius: 3px;
    box-shadow: 0px 0px 0 0px var(--color-supportelements-shadow);
    outline: none;
}

.btn-primary {
    background-color: var(--color-actionelements);
    color: var(--color-actiontext);
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: 0px 0px 5px 3px transparent;
    outline: none;
}

.btn-primary img{
    filter: var(--filter-actiontext);
}
  
.btn-primary:active, .btn-primary.active{
    background-color: var(--color-actionelements-active);
    border: 1px solid transparent;
    box-shadow: 0px 0px 5px 3px transparent;
    outline: none;
}
  
.btn-primary:hover {
    color: var(--color-actiontext);
    border: 1px solid var(--color-actiontext);
    box-shadow: 0px 0px 5px 3px var(--color-actionelements-shadow);
    outline: none;
}

.btn-primary:focus{
    color: var(--color-actiontext);
    border: 1px solid var(--color-actiontext);
    box-shadow: 0px 0px 5px 3px var(--color-actionelements-shadow);
    outline: none;
}

.btn-primary-pressed { /* to be used as the pressed state in toggle groups */
    box-shadow: inset 1px 1px 5px 1px var(--color-actionelements-dark) !important;
    background-color: var(--color-actionelements-shadow) !important;
}

.btn-primary-highlight { /* to be used to highlight the central/most important button on the page */
    box-shadow: 0px 0px 1px 2px var(--color-actionelements-shadow) !important;
}  

.btn-secondary, .btn-secondary-nofilter {
    background-color: var(--color-supportelements);
    color: var(--color-supporttext);
    border: 1px solid transparent;
    border-radius: 3px;
    box-shadow: 0px 0px 5px 3px transparent;
    outline: none;
}

.btn-secondary img {
    filter: var(--filter-supporttext);
}
  
.btn-secondary:active, .btn-secondary.active, .btn-secondary-nofilter:active, .btn-secondary-nofilter.active{
    border: 1px solid var(--color-supportelements);
    background-color: var(--color-supportelements);
    outline: none;
}
  
.btn-secondary:hover, .btn-secondary:focus, .btn-secondary-nofilter:hover, .btn-secondary-nofilter:focus{
    border: 1px solid var(--color-supportelements-active);
    box-shadow: 0px 0px 5px 3px var(--color-supportelements-shadow);
    outline: none;
}

.modal-background{
    background: var(--color-greyout);
    border-radius: 0px;
}
  
.modal-foreground{
    border: 1px solid var(--color-supportelements-active) !important;
    border-radius: 5px;
    background-color: var(--color-modal-background);
    box-shadow: 0px 0px 5px 3px var(--color-supportelements-shadow) !important;
    color: var(--color-actiontext);
}

.modal-foreground div, .modal-foreground p, .modal-foreground label{
    color: var(--color-modal-text);
}

.modal-itemcontainer{
    border-top: 1px solid var(--color-supportelements-active);
    border-bottom: 1px solid var(--color-supportelements-active);
}

.divborder {
    border: 1px solid var(--color-lightgrey); 
    outline: 1px solid var(--color-mediumgrey);
}