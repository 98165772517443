.collapsable{
    --button-height: 1.4em;
}

.collapsable{
    position: relative;
    /*padding: 5px 0;*/
    display: inline-block;
    width: 100%;
}

.collapsable-container {
    padding-top: 2px;
    padding-bottom: 8px;
    display: none;
    overflow: hidden;
}

.collapsable-show {
    display: block;
}

.collapsable-hide {
    display: none;
}

.collapseBarBtn {
    width: 100%;
    height: var(--button-height);
    text-align: left !important;
    margin-top: 2px;
    margin-bottom: 2px;
}

.collapseBarBtn div img {
    height: var(--button-height);
    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.collapseBarBtnText {
    margin: calc(-0.5* var(--button-height)) 0px;
}
