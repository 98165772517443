/* Original Code @ https://codepen.io/WillyW/pen/wZebow */


.visuallyhidden {
  position: absolute;
  z-index: -1;
  right: 0;
  opacity: 0;
}

.carousel-container {
  overflow: visible;
  padding: 0px;
  margin-top: 0em;
  background: transparent;
}

.card-carousel {
  /*
  --card-width: 56%;
  --card-max-width: 56%;
  */
  z-index: 1;
  position: relative;
  margin: 0 auto;
  width: 100%;
  /*height: var(--card-height);*/
  min-width: 0px;
  transition: filter .3s ease;
}

.card-carousel.smooth-return {
  transition: all .3s ease;
}

.card-carousel .card {
  background: transparent;
  /*
  width: var(--card-width);
  max-width: var(--card-max-width);
  */
  text-align: center;
  padding: 0;
  /*min-width: 250px;*/
  /*height: var(--card-height);*/
  position: absolute;
  margin: 0 auto;
  color: white; /*textcolor*/
  transition: inherit;
  /*
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
  */
  border: none;
  border-radius: 5px;
  /*filter: brightness(.9);*/
}

.card-carousel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.card {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card.highlight {
  /*filter: brightness(1)*/
}

.image-container {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  position: relative;
  margin: 5px 0px calc(12px + 2vmin) 0px;
  border-radius: 5px;
  border: none;
  padding: 0;
  background: transparent;
  background-repeat: no-repeat;
  /*
  -webkit-box-shadow: inset 0px 0px 17px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: inset 0px 0px 17px 0px rgba(0,0,0,0.3);
  box-shadow: inset 0px 0px 17px 0px rgba(0,0,0,0.3);
  */
}

.image-container::after {
  content: "";
  display: block;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border-radius: 0%;
  position: absolute;
  top: -5px;
  left: -5px;
}

.card-text {
  display: none; /* show text only for highlighted card */
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 1vmin);
  color: var(--color-text-onbackground);
  background: var(--color-background);
  border-radius: 15px;
  box-shadow: 0px 5px 5px 0px var(--color-background);
  white-space:nowrap;
}

.card.highlight .card-text{
  display: flex;
}
