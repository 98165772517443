/* Typography */

:root {
    --font-size-body: calc(12px + min(1vmin, 7pt)); /*limit to 19pt*/
    --letter-spacing-thintype: 0.02em;
    --letter-spacing-boldtype: 0.02em;
}

body, code, input, select, textarea {  
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: var(--font-size-body);
    font-style: normal;
    letter-spacing: var(--letter-spacing-thintype);
}
  
.btn, button, input, select, textarea {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: var(--font-size-body);
    font-style: normal;
    letter-spacing: var(--letter-spacing-boldtype);
}
 
h1, h2, h3, h4, h5, b {
    font-size: var(--font-size-body);
    font-weight: 400;
    letter-spacing: var(--letter-spacing-boldtype);
}

.App, .app-header, .app-body {
    text-align: center;
    font-size: var(--font-size-body);
    font-weight: 300;
    letter-spacing: var(--letter-spacing-thintype);
}