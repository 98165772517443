.button-radio-selector{
    border: 3px solid transparent;
    background-color: var(--color-background);
    box-shadow: 0px 0px 3px 1px transparent;
    border-radius: 5px;
    position: relative;
    background-size: cover;
    outline: none;
}
  
.button-radio-selector:active, .button-radio-selector.active{
    border: 3px solid var(--color-actionelements);
    background-color: var(--color-background);
    box-shadow: 0px 0px 3px 1px var(--color-actionelements-shadow);
    outline: none;
}
  
.button-radio-selector:hover, .button-radio-selector:focus{
    border: 3px solid var(--color-actionelements-active);
    background-color: var(--color-background);
    box-shadow: 0px 0px 3px 1px var(--color-actionelements-shadow);
    outline: none;
}

.popupselector-background {
    background: var(--color-background);
}