:root {
    --checkbox-size: var(--font-size-body);
}

h1, h2, h3, h4, h5, b {
    margin: 0;
    padding: 0;
    font-weight: bold;
}

/* input elements */

input, select{
    padding-left: 5px;
}

.div-readonlyinput{
    border-radius: 5px;
    outline: 0;
    margin: 1px;
    padding: 5px;
    width: 100%;
    display: block;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.position-absolute {
    position: absolute;
    margin: auto;
}

/* flexbox */

.flex-container-column, .flex-container-row {
    position: relative;
    display: flex; 
    justify-content: flex-start; 
    align-items: center;
    margin: 0;
    width: 100%;
}

.flex-container-column {
    flex-direction: column;
}

.flex-container-row {
    flex-direction: row;
}

/* grid */

.grid-container {
    display: grid;
    grid-gap: 15px 15px; /*  grid-row-gap and the grid-column-gap */
    grid-template-columns: calc(45%-8px) calc(55%-8px); /*default to 2 columns */
    text-align: left;
}

.grid-itemleft {
    grid-column: 1;
}

.grid-itemright {
    grid-column: 2;
}

.grid-itemspan {
    grid-column-start: 1;
    grid-column-end: 3;
}

/* Use button as checkbox */

.btn-checkbox {
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    margin: 0px 10px 0px 0px;
    padding: 0px;
}

/* Use button as togglebutton */

.btn-toggle {
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    margin: calc(0.5*var(--checkbox-size)) 10px -4px 0px; /* top right bottom left */
    padding: 0;
    border-radius: calc(0.5*var(--checkbox-size));
}

/* buttons */

.btn {
    display: inline-flex;
    justify-content: center; /* center the content horizontally */
    align-items: center; /* center the content vertically */
    text-decoration: none;
}

.btn img {
    user-drag: none; 
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.btn[disabled=disabled], .btn:disabled {
    cursor: not-allowed;
    pointer-events: none;
    outline: none;
    margin: 0;
}

/* fix corners in button group */
  
.btn-group-left {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
  
.btn-group-right {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
  
/* Modal styles */
  
.modal-background{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    z-index: 10;
}
  
.modal-foreground{
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 16px;
    width: 80%;
    text-align: left;
}

.modal-itemcontainer {
    flex-wrap: wrap;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.modal-buttoncontainer {
    height: 50px;
    justify-content: space-between;
    margin: 4px;
}

.modal-buttoncontainer button {
    flex: 50% 1 1;
    height: 100%;
    margin: 4px;
}

.modal-buttoncontainer button:first-child {
    margin-left: 0px !important;
}
.modal-buttoncontainer button:last-child {
    margin-right: 0px !important;
}

.modal-close-icon {
  margin-bottom: 2px;
  height: 30px;
  user-drag: none; 
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
  