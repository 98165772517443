

.recorder-portrait-img-container {
    background: var(--color-background);
    box-shadow: 0px 5px 5px 0px var(--color-background);
    z-index: 1;
}

.recorder-portrait-img-container img {
    display: flex;
    margin: auto; 
    border-radius: 5px;
}

.recorder-toggle-btn {
    width: 50%;
    height: 100%;
}